<template>
  <b-modal
    id="lots-by-association-modal"
    hide-header
    hide-footer
    centered
    size="lg"
  >
    <div class="header">
      <div class="header-content">
        <p>Geração de lotes</p>
        <v-close class="close-svg" @click="onClose" />
      </div>
    </div>

    <div class="body">
      <div class="body-content">
        <v-warning class="warning-svg" />
        <p v-if="batches.length">
          Atenção! Serão criados {{ batches.length }} lotes:
          <span
            class="batch-info d-block"
            v-for="(batch, index) of batches"
            :key="index"
          >
            <span class="batch-number">
              Lote N {{ currentBatchNumber + index }}
            </span>
            - {{ batch.tiss_guides_id.length }} guias de {{ getTypes(batch.type) }}
          </span>
        </p>

        <p v-else>
          <span class="batch-info d-block"> 
            Atenção, não há guias disponíveis para geração de lotes!
          </span>
        </p>
        
        <div class="buttons">
          <b-button
            class="button otline"
            size="lg"
            variant="primary"
            outline
            @click="onClose"
          >
            Cancelar
          </b-button>
          <b-button
            :disabled="!batches.length || !userHasPermission('FatTissFat3')"
            class="button"
            size="lg"
            variant="primary"
            @click="storeBatches"
          >
            Gerar lotes
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'
import Warning from '@/assets/icons/warning.svg'
import Close from '@/assets/icons/close.svg'

export default {
  name: 'LotsByAssociationModal',
  components: {
    'v-warning': Warning,
    'v-close': Close
  },
  props: {
    invoice: Object,
    getTypes: Function
  },
  data() {
    return {
      batches: [],
      currentBatchNumber: 0
    }
  },
  methods: {
    userHasPermission,
    onClose() {
      this.$bvModal.hide('lots-by-association-modal')
    },
    async storeBatches() {
      const isLoading = this.$loading.show()
      try {
        await this.api.storeTissBatch({
          tiss_invoice_id: this.invoice.id,
          clinic_health_plan_id: this.invoice.clinic_health_plan_id
        })
        this.$toast.success('Lotes gerados com sucesso!')
        this.onClose()
        this.$emit('change-tab', { tab: 'lotes-a-enviar', index: 2 })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getLotsByAssociation() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTotalBatchesToCreate(
          this.invoice.id,
          this.invoice.clinic_health_plan_id
        )
        this.batches = response.data.total
        this.currentBatchNumber = response.data.currentBatchNumber
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  },
  watch: {
    invoice: {
      async handler(value) {
        if (Object.keys(value).length) {
          await this.getLotsByAssociation()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
#lots-by-association-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 674px;

    .modal-body {
      padding: 0;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--type-active);

      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 24px;

          .close-svg {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .body-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 56px 0;
          font-size: 16px;

          p {
            margin: 40px 0;
          }

          .batch-number {
            font-weight: bold;
            color: var(--blue-500);
          }

          .warning-svg {
            height: 96px;
            width: 96px;
            rect:first-child {
              fill: var(--blue-100);
            }

            rect:nth-child(2) {
              fill: var(--blue-500);
            }
          }

          .buttons {
            display: flex;
            flex-direction: row;

            .button {
              width: 168px;
              height: 40px;
              &:last-child {
                margin-left: 2%;
              }
            }

            .outline {
              margin: 0 5px;
              color: var(--neutral-000);
            }
          }
        }
      }
    }
  }
}
</style>
